import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { ACTIONS as mainActions } from '@/reducers/main'
import { types as modalsAnchorActions } from "@/reducers//modals-reducer";
import { initAnchorModalListener, addModal } from "@/sagas/modals-saga";
import * as main from './main';


function* rootSaga() {
  yield all([
    takeLatest(mainActions.GET_PROJECTS, main.loadProjects),
    takeLatest(mainActions.GET_PROJECT, main.getProject),
    takeLatest(mainActions.GET_PROJECTS_KIND, main.loadProjectsKinds),
    takeLatest(mainActions.GET_CURRENT_SEO, main.getSeo),
    takeEvery(mainActions.GET_RELATED_PROJECT, main.getRelatedProjects),
    takeLatest(modalsAnchorActions.ADD_MODAL, addModal),
    takeLatest(
      modalsAnchorActions.INIT_ANCHOR_MODAL_LISTENER,
      initAnchorModalListener
    ),
    
  ])
}

export default rootSaga