
export const ANIMATIONS = [
  'fadeIn',
  'fadeInDown',
  'fadeInLeft',
  'fadeInRight',
  'fadeInUp',
  /* 'flipInX',
  'slideInUp',
  'slideInDown',
  'slideInLeft',
  'slideInRight',
  'zoomIn',
  'zoomInDown',
  'zoomInLeft',
  'zoomInRight',
  'zoomInUp', */
]


class ScrollAnimation {
  constructor(props) {

    // Establezco como singleton
    ScrollAnimation.__singletonRef = this;


    const { delay, duration } = props;
    this.setUp = false;
    this.delay = delay || 0.1150;
    this.durationPerAnimation = duration || 0.1250;
    this.lastScrollY = 0;
    this.lookForByClassName = [
      'scrollAnimate',
      // ...classNameArray,
    ];
    this.animations = ANIMATIONS;
    this.elements2Animate = [];
  }

  getElements2Animate = () => {
    this.lookForByClassName.forEach((currentClassName) => {
      const elements = [ ...document.getElementsByClassName(currentClassName)];
      let elementsFiltered = [];

      if (elements && elements.length){
        elements.forEach((item) => {
          const isAlreadyAnimated = item.style.getPropertyValue('animation-duration');
          if (!isAlreadyAnimated) {
            elementsFiltered.push(item);
          }
        })
      }
      this.elements2Animate = elementsFiltered;
    })
  }

  isVisible = (element) => {
    let visible = false;
    const windowTop = window.scrollY;
    const windowBottom = windowTop + window.innerHeight;
    const elementPositionTop = window.scrollY + element.getBoundingClientRect().top - (element.offsetHeight);
    if (elementPositionTop < windowBottom && elementPositionTop > windowTop) {
      visible = true;
    }
    return visible;
  }

  getRandomAnimation = () => {
    let animationRes = this.animations[0];
    const max = this.animations.length;
    const index = Math.floor(Math.random() * max);
    if (this.animations && this.animations[index]) {
      animationRes = this.animations[index];
    }
    return animationRes;
  }

  getAnimation = (classList) => {
    let animationRes = this.getRandomAnimation();
    classList.forEach((classname) => {
      if (classname.indexOf('scrollAnimate:') > -1) {
        const tokkens = classname.split(':');
        if (tokkens[1]) {
          animationRes = tokkens[1];
        }
      }
    })
    return animationRes;
  }

  setAnimations = () => {
    const positions2Delete = [];
    const currentScrollY = window.scrollY;
    const diffScrollY = Math.abs(currentScrollY - this.lastScrollY);
    if (diffScrollY > 60 || (this.lastScrollY === 0 && diffScrollY === 0)) {
      let delaySum = this.delay;
      this.elements2Animate.forEach((element, index) => {
        const elementIsOnScreen = this.isVisible(element);
        const classList = [...element.classList];
        if (elementIsOnScreen) {
          element.idScrollAnimation = `${index}`;
          const animation2Apply = this.getAnimation(classList);
          element.classList.add(animation2Apply);
          const css1 = `animation-duration:${this.durationPerAnimation}s; -webkit-animation-duration:${this.durationPerAnimation}s;`;
          const css2 = `${css1} animation-delay:${delaySum}s; -webkit-animation-delay:${delaySum}s;`;
          const css3 = `${element.style.cssText} ${css2}`;
          // element.style.cssText = `${element.style.cssText} animation-delay:${delaySum}s; -webkit-animation-delay:${delaySum}s;`;
          element.style.cssText = `${css3}`;
          
          delaySum += this.delay;
          positions2Delete.push(`${index}`);
        }
      })
      positions2Delete.forEach((idDelete) => {
        const currentIndex = this.elements2Animate.findIndex(item => item.idScrollAnimation === idDelete);
        this.elements2Animate.splice(currentIndex, 1);
      })
    }

    if (this.elements2Animate.length === 0) {
      window.removeEventListener('scroll', this.setAnimations);
    }
  }

  init = () => {
    if (!this.setUp) {
      this.getElements2Animate();
      this.setAnimations();
      window.addEventListener('scroll', this.setAnimations);
    }
  }

  destroy = () => {
    window.removeEventListener('scroll', this.setAnimations);
  }

  static init() {
    ScrollAnimation.__singletonRef.init();
  }

  static refreshElements2Animate() {
    ScrollAnimation.__singletonRef.destroy();
    ScrollAnimation.__singletonRef.init();
  }
}

export default ScrollAnimation;
