import Head from 'next/head';
import { BASE_WEBSITE_URL, TAB_WEB_NAME } from '../config/constants';


const Seo = ({seotitle, seodescription, seokeywords, imagen,imagenWidth, imagenHeight, url}) => {
  let finalImage = !imagen ? `${BASE_WEBSITE_URL}/fb.jpg` : `${BASE_WEBSITE_URL}/admin${imagen}`;
  let finalImagenWidth = !imagen ? 300 : imagenWidth;
  let finalImagenHeight = !imagen ? 300 : imagenHeight;
  let finalUrl = !url ? `${BASE_WEBSITE_URL}`: `${BASE_WEBSITE_URL}${url}`;
  return (
    <Head>
      {seotitle && (
        <title>{`${seotitle} - ${TAB_WEB_NAME}`}</title>
      )}
      {seotitle && (
        <meta property="og:title" content={`${seotitle} - ${TAB_WEB_NAME}`} />
      )}
      {seodescription && (
        <meta name="description" content={`${seodescription}`} />
      )}
      {seodescription && (
        <meta name="og:description" content={`${seodescription}`} />
      )}
      {seokeywords && <meta name="keywords" content={`${seokeywords}`} />}
      <link rel="canonical" href={finalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={finalImage} />
      <meta property="og:image:width" content={finalImagenWidth} />
      <meta property="og:image:height" content={finalImagenHeight} />
      <meta name="author" content={`${TAB_WEB_NAME}`} />
      <meta name="owner" content={`${TAB_WEB_NAME}`} />
      <meta property="og:site_name" content={`${TAB_WEB_NAME}`} />
      <meta property="og:url" content={finalUrl} />
    </Head>
  )
}


export default Seo;
